<template>
    <b-card>
        <b-row class="mb-2">
            <b-col class="text-center">
                <b-form-radio-group
                    id="btn-radios-2"
                    v-model="inventory.inventoryType"
                    :options="inventory.inventoryTypes"
                    button-variant="outline-primary"
                    size="lg"
                    name="radio-btn-outline"
                    buttons
                ></b-form-radio-group>
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col md="4" class="my-1" v-show="inventory.inventoryType === 'ALL' || inventory.inventoryType === 'FLEET'">
                <b-input-group class="inventory-filter">
                    <template #prepend><b-input-group-text>Site</b-input-group-text></template>
                    <select id="inventory-filter-site"><option></option></select>
                </b-input-group>
            </b-col>
            <b-col md="4" class="my-1" v-show="inventory.inventoryType === 'ALL' || inventory.inventoryType === 'FLEET'">
                <b-input-group class="inventory-filter">
                    <template #prepend><b-input-group-text>Unit</b-input-group-text></template>
                    <select id="inventory-filter-unit"><option></option></select>
                </b-input-group>
            </b-col>
            <b-col md="4" class="my-1" v-show="inventory.inventoryType === 'ALL' || inventory.inventoryType === 'WAREHOUSE'">
                <b-input-group class="inventory-filter">
                    <template #prepend><b-input-group-text>Warehouse</b-input-group-text></template>
                    <select id="inventory-filter-warehouse"><option></option></select>
                </b-input-group>
            </b-col>
            <b-col md="4" class="my-1" v-show="inventory.inventoryType === 'ALL' || inventory.inventoryType === 'REPAIR'">
                <b-input-group class="inventory-filter">
                    <template #prepend><b-input-group-text>Service center</b-input-group-text></template>
                    <select id="inventory-filter-service-center"><option></option></select>
                </b-input-group>
            </b-col>
            <b-col md="4" class="my-1" v-show="inventory.inventoryType === 'ALL' || inventory.inventoryType === 'REPAIR'">
                <b-input-group class="inventory-filter">
                    <template #prepend><b-input-group-text>Repair</b-input-group-text></template>
                    <select id="inventory-filter-repair"><option></option></select>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col md="3" class="my-1">
                <b-input-group size="sm" prepend="Show" append="rows">
                    <b-form-select v-model="inventory.itemsPerPage" id="perPageSelect" size="sm" :options="inventory.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col class="text-right">
                <b-button variant="primary" @click="inventoryFormId = 0">
                    <font-awesome-icon icon="plus"></font-awesome-icon> New movement
                </b-button>
            </b-col>
        </b-row>
        <b-table :items="loadData" :fields="inventory.fields" :filter="inventory.filter" :current-page="inventory.currentPage"
                 :per-page="inventory.itemsPerPage" empty-text="Sin registros" empty-filtered-text="Sin coincidencias"
                 striped bordered hover small show-empty :responsive="true" :sort-by="inventory.sortBy" :sort-desc="inventory.sortDesc"
                 ref="inventoryTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in inventory.fields" :key="field.key"
                          :class="inventory.filters[index] && inventory.filters[index].value && typeof inventory.filters[index].value === 'string' ? 'non-empty' : ''">
                        <b-row v-if="inventory.filters[index]">
                            <b-col v-if="inventory.filters[index].inputType === 'Select'">
                                <b-select v-model="inventory.filters[index].value">
                                    <b-select-option value="">Todos</b-select-option>
                                    <b-select-option value="TRUE">Activo</b-select-option>
                                    <b-select-option value="FALSE">Inactivo</b-select-option>
                                </b-select>
                            </b-col>
                            <b-col v-else-if="inventory.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="inventory.filters[index].value" debounce="300"></b-input>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(location)="row">
                <template v-if="row.item.location === 'UNIT'">
                    <b-badge variant="primary" class="p-1" style="width: 5%;">U</b-badge> {{ row.item.unitText }}
                </template>
                <template v-if="row.item.location === 'WAREHOUSE'">
                    <b-badge variant="primary" class="p-1" style="width: 5%;">W</b-badge> {{ row.item.warehouseName }}
                </template>
                <template v-if="row.item.location === 'REPAIR'">
                    <b-badge variant="primary" class="p-1" style="width: 5%;">R</b-badge> {{ row.item.serviceCenterName }}
                    <template v-if="row.item.repairId > 0"> / <router-link :to="{ name: 'RepairShow', params: { id: row.item.repairId }}"># {{ row.item.repairJob }}</router-link></template>
                </template>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" @click="inventoryFormId = row.item.id" v-b-tooltip.hover title="Edit" variant="outline-primary">
                        <font-awesome-icon icon="edit"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Showing {{ start }} to {{ end }} of {{ inventory.totalRows }} row<template v-if="inventory.totalRows !== 1">s</template>
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="inventory.currentPage" :total-rows="inventory.totalRows"
                                      :per-page="inventory.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>

        <InventoryForm :id="inventoryFormId" @reset-inventory-form-id="inventoryFormId = null" @refresh-inventory-table="$refs.inventoryTable.refresh()"></InventoryForm>
    </b-card>
</template>

<script>
import tableStateMixin from '../../mixins/tableState';
import $ from 'jquery';
import InventoryForm from './Form.vue';
import * as constants from '@constants';
import { inventoryListUrl, siteDataUrl, unitDataUrl, warehouseDataUrl, serviceCenterDataUrl, repairDataUrl } from '@routes';

export default {
    components: {
        InventoryForm
    },
    mixins: [tableStateMixin],
    data() {
        return {
            inventory: {
                fields: [
                    { key: 'itemDescription', label: 'Part name', sortable: true },
                    { key: 'cspNumber', label: 'CSP number', sortable: true },
                    { key: 'itemPartNumber', label: 'Part number', sortable: true },
                    { key: 'location', label: 'Location' },
                    { key: 'actions', label: 'Actions', class: 'text-center' }
                ],
                filters: [
                    { column: 'itemDescription', type: 'STRING', value: '' },
                    { column: 'cspNumber', type: 'STRING', value: '' },
                    { column: 'itemPartNumber', type: 'STRING', value: '' },
                    { column: 'location', type: 'STRING', value: '' },
                    { column: 'locationFilter', type: 'NA', value: null }
                ],
                filter: '',
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'b.name',
                sortDesc: false,
                site: null,
                serviceCenter: null,
                inventoryType: 'ALL',
                inventoryTypes: [
                    { text: 'ALL', value: 'ALL' },
                    { text: 'FLEET', value: 'FLEET' },
                    { text: 'WAREHOUSE', value: 'WAREHOUSE' },
                    { text: 'REPAIR', value: 'REPAIR' }
                ]
            },
            breadcrumb: {
                title: 'Inventory',
                path: []
            },
            inventoryFormId: null,
            authorization: `Bearer ${localStorage.getItem('token')}`
        };
    },
    mounted() {
        $('#inventory-filter-site').select2({
            placeholder: 'Site',
            allowClear: true,
            ajax: {
                headers: {
                    'Authorization': this.authorization
                },
                url: siteDataUrl(),
                dataType: 'json',
                method: 'GET',
                data(params) {
                    return { search: params.term };
                },
                processResults(response) {
                    return { results: response.data };
                }
            },
            escapeMarkup: markup => markup,
            templateResult(data) {
                if (data.loading) {
                    return '<a><span>Buscando ...</span></a>';
                }

                return `<strong>${ data.name }</strong>`;
            },
            templateSelection(data) {
                return `<strong>${ data.text || data.name }</strong>`;
            }
        }).on('select2:select', event => {
            let siteData = event.params.data;

            this.inventory.filters[4].value = {
                type: 'SITE',
                id: siteData.id,
                name: siteData.name
            };

            this.inventory.site = {
                id: siteData.id,
                name: siteData.name
            };

            this.initUnitSelect2();

            $('#inventory-filter-unit').empty().trigger('change');
            $('#inventory-filter-warehouse').empty().trigger('change');
            $('#inventory-filter-service-center').empty().trigger('change');
        }).on('select2:unselect', () => {
            this.inventory.site = null;
            this.inventory.filters[4].value = null;

            this.initUnitSelect2();
        });

        this.initUnitSelect2();

        $('#inventory-filter-warehouse').select2({
            placeholder: 'Warehouse',
            allowClear: true,
            ajax: {
                headers: {
                    'Authorization': this.authorization
                },
                url: warehouseDataUrl(),
                dataType: 'json',
                method: 'GET',
                data(params) {
                    return { search: params.term };
                },
                processResults(response) {
                    return { results: response.data };
                }
            },
            escapeMarkup: markup => markup,
            templateResult(data) {
                if (data.loading) {
                    return '<a><span>Buscando ...</span></a>';
                }

                return `<strong>${ data.name }</strong>`;
            },
            templateSelection(data) {
                return `<strong>${ data.text || data.name }</strong>`;
            }
        }).on('select2:select', event => {
            this.inventory.filters[4].value = {
                type: 'WAREHOUSE',
                id: event.params.data.id,
                name: event.params.data.name
            };

            $('#inventory-filter-unit').empty().trigger('change');
            $('#inventory-filter-site').empty().trigger('change');
            $('#inventory-filter-service-center').empty().trigger('change');

            this.inventory.site = null;
        }).on('select2:unselect', () => {
            this.inventory.site = null;
            this.inventory.filters[4].value = null;
        });

        $('#inventory-filter-service-center').select2({
            placeholder: 'Service center',
            allowClear: true,
            ajax: {
                headers: {
                    'Authorization': this.authorization
                },
                url: serviceCenterDataUrl(),
                dataType: 'json',
                method: 'GET',
                data(params) {
                    return { search: params.term };
                },
                processResults(response) {
                    return { results: response.data };
                }
            },
            escapeMarkup: markup => markup,
            templateResult(data) {
                if (data.loading) {
                    return '<a><span>Buscando ...</span></a>';
                }

                return `<strong>${ data.name }</strong>`;
            },
            templateSelection(data) {
                return `<strong>${ data.text || data.name }</strong>`;
            }
        }).on('select2:select', event => {
            let serviceCenterData = event.params.data;

            this.inventory.filters[4].value = {
                type: 'SERVICE_CENTER',
                id: serviceCenterData.id,
                name: serviceCenterData.name
            };

            this.inventory.serviceCenter = {
                id: serviceCenterData.id,
                name: serviceCenterData.name
            };

            this.initRepairSelect2();
            this.inventory.site = null;

            $('#inventory-filter-site').empty().trigger('change');
            $('#inventory-filter-unit').empty().trigger('change');
            $('#inventory-filter-warehouse').empty().trigger('change');
        }).on('select2:unselect', () => {
            this.inventory.site = null;
            this.inventory.serviceCenter = null;
            this.inventory.filters[4].value = null;

            this.initRepairSelect2();
        });

        this.initRepairSelect2();

        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.inventory, this.tableState);

        if (this.inventory.filters.length > 0 && this.inventory.filters[4] && this.inventory.filters[4].value) {
            if (this.inventory.filters[4].value.type === 'UNIT') {
                $('#inventory-filter-unit').append(new Option(`<strong>${ this.inventory.filters[4].value.unitText }</strong>`, this.inventory.filters[4].value.id, true, true)).trigger('change');

                if (this.inventory.filters[4].value.site) {
                    $('#inventory-filter-site').append(new Option(`<strong>${ this.inventory.filters[4].value.site.name }</strong>`, this.inventory.filters[4].value.site.id, true, true)).trigger('change');
                }
            } else if (this.inventory.filters[4].value.type === 'WAREHOUSE') {
                $('#inventory-filter-warehouse').append(new Option(`<strong>${ this.inventory.filters[4].value.name }</strong>`, this.inventory.filters[4].value.name, true, true)).trigger('change');
            } else if (this.inventory.filters[4].value.type === 'REPAIR') {
                $('#inventory-filter-repair').append(new Option(`<strong>${ this.inventory.filters[4].value.repairJob }</strong>`, this.inventory.filters[4].value.id, true, true)).trigger('change');

                if (this.inventory.filters[4].value.serviceCenter) {
                    $('#inventory-filter-service-center').append(new Option(`<strong>${ this.inventory.filters[4].value.serviceCenter.name }</strong>`, this.inventory.filters[4].value.serviceCenter.id, true, true)).trigger('change');
                }
            } else if (this.inventory.filters[4].value.type === 'SITE') {
                $('#inventory-filter-site').append(new Option(`<strong>${ this.inventory.filters[4].value.name }</strong>`, this.inventory.filters[4].value.id, true, true)).trigger('change');
            } else if (this.inventory.filters[4].value.type === 'SERVICE_CENTER') {
                $('#inventory-filter-service-center').append(new Option(`<strong>${ this.inventory.filters[4].value.name }</strong>`, this.inventory.filters[4].value.id, true, true)).trigger('change');
            }
        }
    },
    methods: {
        normalizeText(text) {
            if (text) {
                return text.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u0301]/g, '').normalize();
            }

            return '';
        },
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.inventory.filters.filter(filter => filter.value && ((typeof filter.value === 'string' && filter.value.trim() !== '') || typeof filter.value === 'object')),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(inventoryListUrl(), data).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.inventory.totalRows = response.data.totalRows;
                        return response.data.inventories;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        tableContextChanged(context) {
            this.inventory.sortBy = context.sortBy;
            this.inventory.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.inventory);
        },
        initUnitSelect2() {
            let $this = this;

            if ($('#inventory-filter-unit').hasClass('select2-hidden-accessible')) {
                $('#inventory-filter-unit').empty().append('<option></option>').select2('destroy');
            }

            $('#inventory-filter-unit').select2({
                placeholder: 'Unit',
                allowClear: true,
                ajax: {
                    headers: {
                        'Authorization': this.authorization
                    },
                    url: unitDataUrl(),
                    dataType: 'json',
                    method: 'GET',
                    data(params) {
                        return {
                            search: params.term,
                            siteId: $this.inventory.site ? $this.inventory.site.id : 0
                        };
                    },
                    processResults(response) {
                        return { results: response.data };
                    }
                },
                templateResult(data) {
                    if (!data.id) {
                        return data.text;
                    }

                    return `<strong>${ data.unitText }</strong>`;
                },
                templateSelection(data) {
                    return `<strong>${ data.text || data.unitText }</strong>`;
                },
                escapeMarkup(markup) { return markup; }
            }).on('select2:select', event => {
                let eventData = event.params.data;

                this.inventory.filters[4].value = {
                    type: 'UNIT',
                    id: eventData.id,
                    unitText: eventData.unitText
                };

                if (eventData && eventData.siteId) {
                    this.inventory.filters[4].value.site = {
                        id: eventData.siteId,
                        name: eventData.siteName
                    };

                    this.inventory.site = {
                        id: eventData.siteId,
                        name: eventData.siteName
                    };

                    $('#inventory-filter-site').append(new Option(`<strong>${ eventData.siteName }</strong>`, eventData.siteId, true, true)).trigger('change');
                }

                $('#inventory-filter-warehouse').val('').trigger('change');
            }).on('select2:unselect', () => {
                this.inventory.filters[4].value = null;

                if (this.inventory.site) {
                    this.inventory.filters[4].value = {
                        type: 'SITE',
                        id: this.inventory.site.id,
                        name: this.inventory.site.name
                    };
                }
            });
        },
        initRepairSelect2() {
            let $this = this;

            if ($('#inventory-filter-repair').hasClass('select2-hidden-accessible')) {
                $('#inventory-filter-repair').empty().append('<option></option>').select2('destroy');
            }

            $('#inventory-filter-repair').select2({
                placeholder: 'Repair',
                allowClear: true,
                ajax: {
                    headers: {
                        'Authorization': this.authorization
                    },
                    url: repairDataUrl(),
                    dataType: 'json',
                    method: 'GET',
                    data(params) {
                        return {
                            search: params.term,
                            serviceCenterId: $this.inventory.serviceCenter ? $this.inventory.serviceCenter.id : 0
                        };
                    },
                    processResults(response) {
                        return { results: response.data };
                    }
                },
                templateResult(data) {
                    if (!data.id) {
                        return data.text;
                    }

                    return `<strong># ${ data.repairJob }</strong> ${ data.serviceCenterId ? '<br>' + data.serviceCenterName : '' }`;
                },
                templateSelection(data) {
                    return `<strong>${ data.text || data.repairJob }</strong>`;
                },
                escapeMarkup(markup) { return markup; }
            }).on('select2:select', event => {
                let repairData = event.params.data;

                this.inventory.filters[4].value = {
                    type: 'REPAIR',
                    id: repairData.id,
                    repairJob: repairData.repairJob
                };

                if (repairData && repairData.serviceCenterId) {
                    this.inventory.filters[4].value.serviceCenter = {
                        id: repairData.serviceCenterId,
                        name: repairData.serviceCenterName
                    };

                    this.inventory.serviceCenter = {
                        id: repairData.serviceCenterId,
                        name: repairData.serviceCenterName
                    };

                    $('#inventory-filter-service-center').append(new Option(`<strong>${ repairData.serviceCenterName }</strong>`, repairData.serviceCenterId, true, true)).trigger('change');
                }
            }).on('select2:unselect', () => {
                this.inventory.filters[4].value = null;

                if (this.inventory.serviceCenter) {
                    this.inventory.filters[4].value = {
                        type: 'SERVICE_CENTER',
                        id: this.inventory.serviceCenter.id,
                        name: this.inventory.serviceCenter.name
                    };
                }
            });
        }
    },
    watch: {
        'inventory.filters': {
            handler() {
                this.inventory.currentPage = 1;
                this.$refs.inventoryTable.refresh();
                this.saveTableState(this.$route.name, this.inventory);
            },
            deep: true
        },
        'inventory.inventoryType'() {
            let inventoryTypeFilter = this.inventory.filters.find(object => object.column === 'inventoryType');

            if (!inventoryTypeFilter) {
                this.inventory.filters.push({
                    column: 'inventoryType',
                    type: 'NA',
                    value: this.inventory.inventoryType
                });
            } else {
                inventoryTypeFilter.value = this.inventory.inventoryType;
            }
        }
    },
    computed: {
        start: function() {
            return this.inventory.totalRows === 0 ? 0 : (this.inventory.currentPage - 1) * this.inventory.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.inventory.currentPage - 1) * this.inventory.itemsPerPage;

            return (this.inventory.totalRows - offset) < this.inventory.itemsPerPage ? this.inventory.totalRows : offset + this.inventory.itemsPerPage;
        }
    }
};
</script>
<style>
    .inventory-filter {
        flex-wrap: unset;
    }
</style>