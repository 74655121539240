const CODE_OK = 'OK';
const CODE_NOK = 'NOK';
const DEFAULT_ERROR_MESSAGE = 'Ha ocurrido un error. Por favor contacte a soporte.';
const FORBIDDEN_ERROR_MESSAGE = 'No cuentas con autorización para realizar esta acción';

module.exports = {
    CODE_OK,
    CODE_NOK,
    DEFAULT_ERROR_MESSAGE,
    FORBIDDEN_ERROR_MESSAGE
};