let tableStateMixin = {
    data() {
        return {
            tableState: {}
        };
    },
    created() {
        if (!localStorage.getItem('tableState')) {
            localStorage.setItem('tableState', JSON.stringify({}));
        }

        let tableState = JSON.parse(localStorage.getItem('tableState'));

        if (this.$route.name in tableState) {
            this.tableState = tableState[this.$route.name];
        } else {
            this.tableState = {
                filters: [],
                currentPage: 1,
                itemsPerPage: 25,
                totalRows: 0,
                sortBy: '',
                sortDesc: false,
                filter: ''
            };
        }
    },
    methods: {
        restoreTableState(state, initialState) {
            for (let filter of state.filters) {
                let filterState = initialState.filters.find(state => state.column === filter.column);

                if (filterState) {
                    filter.value = filterState.value;
                }
            }

            state.currentPage = initialState.currentPage;
            state.itemsPerPage = initialState.itemsPerPage;
            state.sortBy = initialState.sortBy !== '' ? initialState.sortBy : state.sortBy;
            state.sortDesc = initialState.sortDesc;
            state.totalRows = initialState.totalRows;
            state.filter = initialState.filter;
        },
        saveTableState(route, state) {
            let tableState = JSON.parse(localStorage.getItem('tableState'));

            tableState[route] = {
                filters: state.filters,
                currentPage: state.currentPage,
                itemsPerPage: state.itemsPerPage,
                totalRows: state.totalRows,
                sortBy: state.sortBy,
                sortDesc: state.sortDesc,
                filter: state.filter
            };

            localStorage.setItem('tableState', JSON.stringify(tableState));
        }
    }
};

export default tableStateMixin;