<template>
    <b-modal :id="modalId" ref="modal" size="lg" centered no-close-on-backdrop no-close-on-esc>
        <template #modal-header>
            <h5>New movement</h5>
        </template>
        <b-overlay :show="isLoading">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveInventory)" id="inventory-form" v-if="inventory">
                    <b-row>
                        <b-col cols="6" class="text-left">
                            <b-form-group label="Type">
                                <b-form-radio v-model="inventory.movementType" name="inventory-movement-type" value="IN" class="custom-radio" :disabled="inventory.id > 0">IN</b-form-radio>
                                <b-form-radio v-model="inventory.movementType" name="inventory-movement-type" value="OUT" class="custom-radio">OUT</b-form-radio>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="text-left" v-if="inventory.movementType === 'OUT'">
                            <b-form-group label="Reason">
                                <b-form-radio v-model="inventory.movementReason" name="inventory-movement-reason" value="TRANSFER" class="custom-radio">Transfer</b-form-radio>
                                <b-form-radio v-model="inventory.movementReason" name="inventory-movement-reason" value="SCRAP" class="custom-radio">Scrap</b-form-radio>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="12" class="text-left">
                            <b-form-group label="Part">
                                <select id="inventory-form-part" :disabled="inventory.id > 0"></select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="8" md="8" class="text-left">
                            <b-form-group label="Part name">
                                <b-form-input v-if="inventory.item" v-model="inventory.item.description" autocomplete="false" disabled></b-form-input>
                                <b-form-input v-else placeholder="Part name" autocomplete="false" disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" md="4" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="CSP Number" invalid-feedback="Enter the CSP number">
                                    <b-form-input v-model="inventory.cspNumber" autocomplete="false" placeholder="CSP Number"
                                                  :state="errors[0] ? false : null" :disabled="inventory.movementType !== 'IN'"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row v-if="inventory.movementType === 'OUT'">
                        <b-col cols="3" md="3" class="text-left" v-if="inventory.currentLocation">
                            <b-form-group label="Current Location">
                                <p>{{ inventory.currentLocation }}</p>
                            </b-form-group>
                        </b-col>
                        <b-col cols="5" md="5" class="text-left">
                            <b-form-group label="Current unit" v-if="inventory.currentLocation === 'UNIT'">
                                {{ inventory.currentUnit.name }}
                            </b-form-group>
                            <b-form-group label="Current warehouse" v-if="inventory.currentLocation === 'WAREHOUSE'">
                                {{ inventory.currentWarehouse.name }}
                            </b-form-group>
                            <b-form-group label="Current service center" v-if="inventory.currentLocation === 'REPAIR'">
                                {{ inventory.currentServiceCenter ? inventory.currentServiceCenter.name : '' }}
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" md="4" class="text-left" v-if="inventory.currentLocation === 'REPAIR'">
                            <b-form-group label="Repair">
                                {{ inventory.currentRepair ? inventory.currentRepair.repairJob : '' }}
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="inventory.movementType === 'IN' || (inventory.movementType === 'OUT' && inventory.movementReason === 'TRANSFER')">
                        <b-col cols="3" md="3" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Location" invalid-feedback="Enter the location">
                                    <b-form-select v-model="inventory.location" :options="locations" :state="errors[0] ? false : null">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled>Please select an option</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="2" md="4" class="text-left" v-show="inventory.location === 'UNIT'">
                            <b-form-group label="Site">
                                <select id="inventory-form-site"></select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="5" md="5" class="text-left" v-show="inventory.location === 'UNIT'">
                            <b-form-group label="Unit">
                                <select id="inventory-form-unit"></select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="5" md="5" class="text-left" v-show="inventory.location === 'WAREHOUSE'">
                            <b-form-group label="Warehouse">
                                <select id="inventory-form-warehouse"></select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="5" md="5" class="text-left" v-show="inventory.location === 'REPAIR'">
                            <b-form-group label="Service center">
                                <select id="inventory-form-service-center"></select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" md="4" class="text-left" v-show="inventory.location === 'REPAIR'">
                            <b-form-group label="Repair">
                                <select id="inventory-form-repair"></select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Notes">
                                <b-textarea v-model="inventory.notes" placeholder="Notes"></b-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
        </b-overlay>
        <template #modal-footer>
            <div class="text-right">
                <b-button @click="cancelAction" class="mr-2" variant="default"><font-awesome-icon icon="ban"></font-awesome-icon> Cancel</b-button>
                <b-button type="submit" form="inventory-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Save</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { extend } from 'vee-validate';
import {
    inventorySaveUrl,
    itemDataUrl,
    unitDataUrl,
    warehouseDataUrl,
    inventoryUrl,
    inventoryDataUrl,
    siteDataUrl,
    serviceCenterDataUrl,
    repairDataUrl
} from '@routes';
import * as constants from '@constants';
import { required } from 'vee-validate/dist/rules';
import $ from 'jquery';

extend('required', {
    ...required
});

export default {
    name: 'InventoryForm',
    props: {
        id: Number
    },
    data() {
        return {
            modalId: 'inventory-form-modal',
            toastTitle: 'Parts',
            inventory: {
                id: 0,
                item: null,
                location: '',
                unit: null,
                warehouse: null,
                serviceCenter: null,
                repair: null,
                qty: 1,
                cspNumber: '',
                movementType: 'IN',
                movementReason: '',
                site: null,
                notes: '',
                currentLocation: '',
                currentUnit: null,
                currentWarehouse: null,
                currentServiceCenter: null,
                currentRepair: null
            },
            isLoading: false,
            authorization: `Bearer ${localStorage.getItem('token')}`,
            locations: [
                { value: 'UNIT', text: 'UNIT' },
                { value: 'WAREHOUSE', text: 'WAREHOUSE' },
                { value: 'REPAIR', text: 'REPAIR' }
            ]
        };
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(inventoryUrl(this.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.inventory.id = response.data.inventory.id;
                    this.inventory.item = response.data.inventory.item;
                    this.inventory.currentLocation = response.data.inventory.location;
                    this.inventory.currentUnit = response.data.inventory.unit;
                    this.inventory.currentWarehouse = response.data.inventory.warehouse;
                    this.inventory.currentServiceCenter = response.data.inventory.serviceCenter;
                    this.inventory.currentRepair = response.data.inventory.repair;
                    this.inventory.qty = response.data.inventory.qty;
                    this.inventory.cspNumber = response.data.inventory.cspNumber;
                    this.inventory.movementType = 'OUT';
                    this.inventory.movementReason = 'TRANSFER';
                }

                this.$bvModal.show('inventory-form-modal');

                this.$nextTick(() => {
                    this.initPartSelect2();
                });
            }).catch(error => {
                console.error(error);

                this.$emit('reset-inventory-form-id');

                this.$bvToast.toast('There was an error getting the inventory data', {
                    title: this.toastTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        saveInventory() {
            this.isLoading = true;

            this.axios.post(inventorySaveUrl(), this.inventory).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });
                    this.inventory.location = '';
                    this.$bvModal.hide(this.modalId);
                    this.$emit('reset-inventory-form-id');
                    this.$emit('refresh-inventory-table');
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                console.error(error);

                this.$bvToast.toast('There was an error saving the part', {
                    title: this.toastTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        cancelAction() {
            this.inventory.location = '';
            this.$bvModal.hide(this.modalId);
            this.$emit('reset-inventory-form-id');
        },
        initPartSelect2() {
            let $this = this;
            let url = itemDataUrl();

            if (this.inventory.movementType !== 'IN') {
                url = inventoryDataUrl();
            }

            if ($('#inventory-form-part').hasClass('select2-hidden-accessible')) {
                $('#inventory-form-part').empty().trigger('change').select2('destroy');
            }

            $('#inventory-form-part').select2({
                placeholder: 'Part',
                dropdownParent: $('#inventory-form'),
                ajax: {
                    headers: {
                        'Authorization': this.authorization
                    },
                    url: url,
                    dataType: 'json',
                    method: 'GET',
                    data(params) {
                        return { search: params.term };
                    },
                    processResults(response) {
                        return { results: response.data };
                    }
                },
                escapeMarkup: markup => markup,
                templateResult(data) {
                    if (data.loading) {
                        return '<a><span>Buscando ...</span></a>';
                    }

                    if ($this.inventory.movementType !== 'IN') {
                        let locationBadge = '';
                        let locationText = '';

                        if (data.location === 'UNIT') {
                            locationBadge = '<b-badge variant="primary" className="p-1" style="width: 5%;" class="select2-badge-primary float-right">U</b-badge>';
                            locationText = data.unitText;
                        } else if (data.location === 'WAREHOUSE') {
                            locationBadge = '<b-badge variant="primary" className="p-1" style="width: 5%;" class="select2-badge-primary float-right">W</b-badge>';
                            locationText = data.warehouseName;
                        }

                        return `<strong>${ data.partNumber }</strong> / ${ data.cspNumber } ${ locationBadge }
                                </br>${ data.description }
                                <br>${ locationText }`;
                    }

                    return `<strong>${ data.partNumber }</strong> - ${ data.description }`;
                },
                templateSelection(data) {
                    return `<strong>${ data.text || data.partNumber }</strong>`;
                }
            }).on('select2:select', event => {
                let itemData = event.params.data;

                this.inventory.item = {
                    id: ($this.inventory.movementType === 'OUT' ? itemData.itemId : itemData.id),
                    partNumber: itemData.partNumber,
                    description: itemData.description
                };

                // itemId

                if ($this.inventory.movementType === 'OUT') {
                    this.inventory.cspNumber = itemData.cspNumber;
                    this.inventory.currentLocation = itemData.location;

                    if (itemData.location === 'UNIT') {
                        this.inventory.currentUnit = {
                            id: itemData.unitId,
                            name: itemData.unitText
                        };
                    } else if (itemData.location === 'WAREHOUSE') {
                        this.inventory.currentWarehouse = {
                            id: itemData.warehouseId,
                            name: itemData.warehouseName
                        };
                    }
                }
            }).on('select2:unselect', () => {
                this.inventory.item = null;
            });

            if (this.inventory.item) {
                $('#inventory-form-part').append(new Option(`<strong>${ this.inventory.item.partNumber }</strong>`, this.inventory.item.id, true, true)).trigger('change');
            }
        },
        initSiteSelect2() {
            if ($('#inventory-form-site').hasClass('select2-hidden-accessible')) {
                $('#inventory-form-site').empty().append('<option></option>').select2('destroy');
            }

            $('#inventory-form-site').select2({
                placeholder: 'Site',
                allowClear: true,
                dropdownParent: $('#inventory-form'),
                ajax: {
                    headers: {
                        'Authorization': this.authorization
                    },
                    url: siteDataUrl(),
                    dataType: 'json',
                    method: 'GET',
                    data(params) {
                        return { search: params.term };
                    },
                    processResults(response) {
                        return { results: response.data };
                    }
                },
                escapeMarkup: markup => markup,
                templateResult(data) {
                    if (data.loading) {
                        return '<a><span>Buscando ...</span></a>';
                    }

                    return `<strong>${ data.name }</strong>`;
                },
                templateSelection(data) {
                    return `<strong>${ data.text || data.name }</strong>`;
                }
            }).on('select2:select', event => {
                let siteData = event.params.data;

                this.inventory.site = {
                    id: siteData.id,
                    name: siteData.name
                };
                this.inventory.unit = null;
                this.initUnitSelect2();
            }).on('select2:unselect', () => {
                this.inventory.site = null;
                this.initUnitSelect2();
            });

            this.initUnitSelect2();
        },
        initUnitSelect2() {
            let $this = this;

            if ($('#inventory-form-unit').hasClass('select2-hidden-accessible')) {
                $('#inventory-form-unit').empty().append('<option></option>').select2('destroy');
            }

            $('#inventory-form-unit').empty().trigger('change').select2({
                placeholder: 'Unit',
                dropdownParent: $('#inventory-form'),
                ajax: {
                    headers: {
                        'Authorization': this.authorization
                    },
                    url: unitDataUrl(),
                    dataType: 'json',
                    method: 'GET',
                    data(params) {
                        return {
                            search: params.term,
                            siteId: $this.inventory.site ? $this.inventory.site.id : 0
                        };
                    },
                    processResults(response) {
                        return { results: response.data };
                    }
                },
                escapeMarkup: markup => markup,
                templateResult(data) {
                    if (data.loading) {
                        return '<a><span>Buscando ...</span></a>';
                    }

                    return `<strong>${ data.unitText }</strong>`;
                },
                templateSelection(data) {
                    return data.text || `<strong>${ data.unitText }</strong>`;
                }
            }).on('select2:select', event => {
                let unitData = event.params.data;

                this.inventory.unit = {
                    id: unitData.id,
                    name: unitData.name,
                    alias: unitData.alias
                };
            }).on('select2:unselect', () => {
                this.inventory.unit = null;
            });

            if (this.inventory.location === 'UNIT' && this.inventory.unit) {
                $('#inventory-form-unit').append(new Option(`<strong>${ this.inventory.unit.name }</strong>`, this.inventory.unit.id, true, true)).trigger('change');
            }
        },
        initWarehouseSelect2() {
            $('#inventory-form-warehouse').empty().trigger('change').select2({
                placeholder: 'Warehouse',
                dropdownParent: $('#inventory-form'),
                ajax: {
                    headers: {
                        'Authorization': this.authorization
                    },
                    url: warehouseDataUrl(),
                    dataType: 'json',
                    method: 'GET',
                    data(params) {
                        return { search: params.term };
                    },
                    processResults(response) {
                        return { results: response.data };
                    }
                },
                escapeMarkup: markup => markup,
                templateResult(data) {
                    if (data.loading) {
                        return '<a><span>Buscando ...</span></a>';
                    }

                    return `<strong>${ data.name }</strong>`;
                },
                templateSelection(data) {
                    return data.text || `<strong>${ data.name }</strong>`;
                }
            }).on('select2:select', event => {
                let warehouseData = event.params.data;

                this.inventory.warehouse = {
                    id: warehouseData.id,
                    name: warehouseData.name
                };
            }).on('select2:unselect', () => {
                this.inventory.warehouse = null;
            });

            if (this.inventory.location === 'WAREHOUSE' && this.inventory.warehouse) {
                $('#inventory-form-warehouse').append(new Option(`<strong>${ this.inventory.warehouse.name }</strong>`, this.inventory.warehouse.id, true, true)).trigger('change');
            }
        },
        initServiceCenterSelect2() {
            let $this = this;

            if ($('#inventory-form-service-center').hasClass('select2-hidden-accessible')) {
                $('#inventory-form-service-center').empty().append('<option></option>').select2('destroy');
            }

            $('#inventory-form-service-center').empty().trigger('change').select2({
                placeholder: 'Service center',
                dropdownParent: $('#inventory-form'),
                ajax: {
                    headers: {
                        'Authorization': this.authorization
                    },
                    url: serviceCenterDataUrl(),
                    dataType: 'json',
                    method: 'GET',
                    data(params) {
                        return {
                            search: params.term
                        };
                    },
                    processResults(response) {
                        return { results: response.data };
                    }
                },
                escapeMarkup: markup => markup,
                templateResult(data) {
                    if (data.loading) {
                        return '<a><span>Buscando ...</span></a>';
                    }

                    return `<strong>${ data.name }</strong>`;
                },
                templateSelection(data) {
                    return data.text || `<strong>${ data.name }</strong>`;
                }
            }).on('select2:select', event => {
                let serviceCenterData = event.params.data;

                this.inventory.serviceCenter = {
                    id: serviceCenterData.id,
                    name: serviceCenterData.name
                };

                $this.initRepairSelect2();
            }).on('select2:unselect', () => {
                this.inventory.serviceCenter = null;

                $this.initRepairSelect2();
            });

            if (this.inventory.location === 'REPAIR' && this.inventory.serviceCenter) {
                $('#inventory-form-service-center').append(new Option(`<strong>${ this.inventory.serviceCenter.name }</strong>`, this.inventory.serviceCenter.id, true, true)).trigger('change');
            }

            $this.initRepairSelect2();
        },
        initRepairSelect2() {
            let $this = this;

            if ($('#inventory-form-repair').hasClass('select2-hidden-accessible')) {
                $('#inventory-form-repair').empty().append('<option></option>').select2('destroy');
            }

            $('#inventory-form-repair').empty().trigger('change').select2({
                placeholder: 'Repair',
                dropdownParent: $('#inventory-form'),
                ajax: {
                    headers: {
                        'Authorization': this.authorization
                    },
                    url: repairDataUrl(),
                    dataType: 'json',
                    method: 'GET',
                    data(params) {
                        return {
                            search: params.term,
                            serviceCenterId: $this.inventory.serviceCenter ? $this.inventory.serviceCenter.id : 0
                        };
                    },
                    processResults(response) {
                        return { results: response.data };
                    }
                },
                escapeMarkup: markup => markup,
                templateResult(data) {
                    if (data.loading) {
                        return '<a><span>Buscando ...</span></a>';
                    }

                    return `<strong># ${ data.repairJob }</strong> ${ data.serviceCenterId ? '<br>' + data.serviceCenterName : '' }`;
                },
                templateSelection(data) {
                    return `<strong>${ data.text || data.repairJob }</strong>`;
                }
            }).on('select2:select', event => {
                let repairData = event.params.data;

                this.inventory.repair = {
                    id: repairData.id,
                    repairJob: repairData.repairJob
                };

                if (repairData && repairData.serviceCenterId) {
                    this.inventory.serviceCenter = {
                        id: repairData.serviceCenterId,
                        name: repairData.serviceCenterName
                    };

                    $('#inventory-form-service-center').append(new Option(`<strong>${ repairData.serviceCenterName }</strong>`, repairData.serviceCenterId, true, true)).trigger('change');
                }
            }).on('select2:unselect', () => {
                this.inventory.repair = null;
            });

            if (this.inventory.location === 'REPAIR' && this.inventory.repair) {
                $('#inventory-form-repair').append(new Option(`<strong>${ this.inventory.repair.repairJob }</strong>`, this.inventory.repair.id, true, true)).trigger('change');
            }
        }
    },
    watch: {
        id() {
            if (this.id !== null) {
                if (this.id > 0) {
                    this.loadData();
                } else {
                    this.inventory = {
                        id: 0,
                        item: null,
                        location: '',
                        unit: null,
                        warehouse: null,
                        serviceCenter: null,
                        qty: 1,
                        cspNumber: '',
                        movementType: 'IN',
                        movementReason: '',
                        site: null,
                        notes: '',
                        currentLocation: '',
                        currentUnit: null,
                        currentWarehouse: null,
                        currentServiceCenter: null,
                        currentRepair: null
                    };

                    this.$bvModal.show('inventory-form-modal');

                    this.$nextTick(() => {
                        this.initPartSelect2();
                    });
                }
            }
        },
        'inventory.location'() {
            this.$nextTick(() => {
                if (this.inventory.location === 'UNIT') {
                    this.initSiteSelect2();
                } else if (this.inventory.location === 'WAREHOUSE') {
                    this.initWarehouseSelect2();
                } else if (this.inventory.location === 'REPAIR') {
                    this.initServiceCenterSelect2();
                }
            });
        },
        'inventory.movementType'() {
            if (this.inventory.id === 0) {
                this.inventory.item = null;
                this.inventory.cspNumber = '';
                this.inventory.item = null;
                this.inventory.location = '';
                this.inventory.unit = null;
                this.inventory.warehouse = null;
                this.inventory.serviceCenter = null;
                this.inventory.currentLocation = '';
                this.inventory.currentUnit = null;
                this.inventory.currentWarehouse = null;
                this.inventory.currentServiceCenter = null;
                this.inventory.currentRepair = null;
            }

            if (this.inventory.movementType === 'IN') {
                this.inventory.movementReason = '';
            } else if (this.inventory.movementType === 'OUT') {
                this.inventory.movementReason = 'TRANSFER';
            }

            this.initPartSelect2();
        },
        'inventory.movementReason'() {
            this.inventory.location = '';
            this.inventory.unit = null;
            this.inventory.warehouse = null;
            this.inventory.serviceCenter = null;
        }
    }
};
</script>
<style>
    .custom-radio {
        display: inline-table !important;
        margin-right: 10px !important;
    }

    .custom-radio label {
        font-weight: normal !important;
        margin-top: 0 !important;
    }

    .select2-badge-primary {
        background-color: #73d5ff !important;
        color: #0082ba !important;
        border: 1px #73d5ff solid !important;
        border-radius: 2.5px !important;
        font-size: 80% !important;
        font-weight: 600 !important;
        line-height: 1 !important;
        padding: 0.25rem !important;
        width: fit-content !important;
    }
</style>